exports.components = {
  "component---src-pages-advertising-js": () => import("./../../../src/pages/advertising.js" /* webpackChunkName: "component---src-pages-advertising-js" */),
  "component---src-pages-assets-brand-js": () => import("./../../../src/pages/assets-brand.js" /* webpackChunkName: "component---src-pages-assets-brand-js" */),
  "component---src-pages-assets-devices-js": () => import("./../../../src/pages/assets-devices.js" /* webpackChunkName: "component---src-pages-assets-devices-js" */),
  "component---src-pages-assets-headshots-js": () => import("./../../../src/pages/assets-headshots.js" /* webpackChunkName: "component---src-pages-assets-headshots-js" */),
  "component---src-pages-assets-lifestyle-js": () => import("./../../../src/pages/assets-lifestyle.js" /* webpackChunkName: "component---src-pages-assets-lifestyle-js" */),
  "component---src-pages-assets-list-js": () => import("./../../../src/pages/assets-list.js" /* webpackChunkName: "component---src-pages-assets-list-js" */),
  "component---src-pages-assets-logos-js": () => import("./../../../src/pages/assets-logos.js" /* webpackChunkName: "component---src-pages-assets-logos-js" */),
  "component---src-pages-assets-screenshots-js": () => import("./../../../src/pages/assets-screenshots.js" /* webpackChunkName: "component---src-pages-assets-screenshots-js" */),
  "component---src-pages-betawards-js": () => import("./../../../src/pages/betawards.js" /* webpackChunkName: "component---src-pages-betawards-js" */),
  "component---src-pages-free-trial-2021-js": () => import("./../../../src/pages/free-trial-2021.js" /* webpackChunkName: "component---src-pages-free-trial-2021-js" */),
  "component---src-pages-gift-offer-terms-js": () => import("./../../../src/pages/gift-offer-terms.js" /* webpackChunkName: "component---src-pages-gift-offer-terms-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-info-js": () => import("./../../../src/pages/info.js" /* webpackChunkName: "component---src-pages-info-js" */),
  "component---src-pages-legal-js": () => import("./../../../src/pages/legal.js" /* webpackChunkName: "component---src-pages-legal-js" */),
  "component---src-pages-press-js": () => import("./../../../src/pages/press.js" /* webpackChunkName: "component---src-pages-press-js" */),
  "component---src-pages-public-ip-js": () => import("./../../../src/pages/public-ip.js" /* webpackChunkName: "component---src-pages-public-ip-js" */),
  "component---src-pages-terms-js": () => import("./../../../src/pages/terms.js" /* webpackChunkName: "component---src-pages-terms-js" */),
  "component---src-templates-asset-js": () => import("./../../../src/templates/asset.js" /* webpackChunkName: "component---src-templates-asset-js" */),
  "component---src-templates-markdown-js": () => import("./../../../src/templates/markdown.js" /* webpackChunkName: "component---src-templates-markdown-js" */)
}

